<template>
  <div class="role">
    <div class="users_form">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item label="角色名稱">
          <el-input
            v-model="searchForm.role_name"
            placeholder="請輸入角色名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色名稱">
          <el-input
            v-model="searchForm.roler_key"
            placeholder="請輸入角色名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="創建時間">
          <el-date-picker
            v-model="createDate"
            type="daterange"
            range-separator="~"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getRoleList">搜索</el-button>
          <el-button type="success" @click="addRole">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="tableLoading"
      :data="roleList"
      :border="true"
      class="table"
    >
      <el-table-column
        prop="role_id"
        label="ID"
        align="center"
        width="100"
      ></el-table-column>

      <el-table-column prop="role_name" label="角色名稱"></el-table-column>
      <el-table-column label="狀態" prop="status" width="150">
        <template slot-scope="scope">
          <el-tag
            size="small"
            :type="scope.row.status == 1 ? 'success' : 'danger'"
            >{{ scope.row.status == 1 ? "正常" : "停用" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="sort_num" label="排序"> </el-table-column>
      <el-table-column prop="create_time" label="創建時間"> </el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="jurisdiction(scope.row.id)"
            >權限</el-button
          > -->
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="editRole(scope.row.role_id)"
            >編輯</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="delRole(scope.row.role_id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加/修改角色-->
    <el-dialog
      :title="roleTitle + '角色'"
      :visible.sync="roleVisible"
      :before-close="handelClose"
      width="600px"
    >
      <el-form
        ref="roleForm"
        :model="roleForm"
        :rules="roleFormRules"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色名稱" prop="role_name">
              <el-input
                v-model="roleForm.role_name"
                placeholder="請輸入角色名稱"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="role_key">
              <span slot="label">
                <el-tooltip
                  content="控制器中定義的權限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                權限字符
              </span>
              <el-input
                v-model="roleForm.role_key"
                placeholder="請輸入權限字符"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色順序" prop="sort_num">
              <el-input-number
                v-model="roleForm.sort_num"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="狀態">
              <el-radio-group v-model="roleForm.status">
                <el-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜單類型">
              <!--              <el-radio-group v-model="mold_type">-->
              <!--                <el-radio-->
              <!--                        v-for="item in statusList"-->
              <!--                        :key="item.value"-->
              <!--                        :label="item.value"-->
              <!--                >{{ item.label }}</el-radio-->
              <!--                >-->
              <!--              </el-radio-group>-->
              <el-radio-group v-model="mold_type" @change="changeMenuType">
                <el-radio :label="1">總後台菜單</el-radio>
                <el-radio :label="2">商戶後台菜單</el-radio>
              </el-radio-group>
              <!--              <el-radio v-model="mold_type" label="1">備選項</el-radio>-->
              <!--              <el-radio v-model="mold_type" label="2">備選項</el-radio>-->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜單權限">
              <el-checkbox
                v-model="menuNodeAll"
                @change="handleCheckedTreeNodeAll($event)"
                >全選/全不選</el-checkbox
              >
              <el-tree
                class="tree-border"
                :data="menuOptions"
                show-checkbox
                ref="menu"
                node-key="menu_id"
                empty-text="加載中，請稍候"
                :props="defaultProps"
              ></el-tree>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="備註">
              <el-input
                v-model="roleForm.remark"
                type="textarea"
                placeholder="請輸入內容"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="roleSubmit"
          >提交</el-button
        >
        <!-- <el-button size="small" @click="roleReset">重置</el-button> -->
      </span>
    </el-dialog>
    <!-- 權限 -->
    <el-dialog title="提示" :visible.sync="Visible" width="400px">
      <el-tree
        :data="jurisdictionlist"
        show-checkbox
        node-key="id"
        ref="tree"
        :props="defaultProps"
        :default-expand-all="true"
        :check-on-click-node="true"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Visible = false">取 消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      searchForm: {
        role_name: "",
        roler_key: "",
      },
      mold_type: 1,
      createDate: "",
      page: 1,
      pageSize: 50,
      total: 0,
      roleList: [],
      // 添加、修改
      roleVisible: false,
      roleTitle: "添加",
      selectRoleId: "",
      statusList: [
        { label: "正常", value: 1 },
        { label: "禁用", value: 2 },
      ],
      roleForm: {},
      roleFormRules: {
        role_name: [
          { required: true, message: "請輸入角色名稱", trigger: "blur" },
        ],
        sort_num: [{ required: true, message: "請輸入排序", trigger: "blur" }],
      },
      menuOptions: [],
      defaultProps: {
        children: "children",
        label: "menu_name",
      },
      // 是否展開
      menuExpand: false,
      // 全選全部選
      menuNodeAll: false,
      checkedKeyList: [],

      dialogVisible: false,
      Visible: false,
      compilelist: {},
      jurisdictionlist: [],
      role_id: {
        id: "",
      },

      arr: [],
      roleCheckedList: [],
    };
  },
  mounted() {
    this.getRoleList();
    this.getMenuTreeselect();
  },
  methods: {
    getRoleList() {
      this.tableLoading = true;
      this.$api
        .getRole({
          page: this.page,
          pageSize: this.pageSize,
          roler_key: this.searchForm.roler_key,
          role_name: this.searchForm.role_name,
          create_time_from:
            this.createDate == "" || this.createDate == null
              ? ""
              : this.createDate[0],
          create_time_to:
            this.createDate == "" || this.createDate == null
              ? ""
              : this.createDate[1],
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.code == 200) {
            this.roleList = res.data.list;
            this.total = res.data.count;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeMenuType() {
      // console.log("11111");
      // console.log(this.mold_type);
      this.getMenuTreeselect();
      this.selectMenu(this.selectRoleId);
    },
    // 表单重置
    handelClose() {
      this.$refs.roleForm.resetFields();
      this.roleVisible = false;
      this.roleForm = {
        role_name: "",
        role_key: "",
        sort_num: "",
        status: 1,
        remark: "",
        menu_ids: "",
      };
    },
    /** 查詢菜單樹結構 */
    getMenuTreeselect() {
      this.$api
        .getMenu({
          pageSize: 9999,
          page: 1,
          menu_name: "",
          mold_type: this.mold_type,
        })
        .then((res) => {
          this.menuOptions = res.data.list;
        });
    },
    handleCheckedTreeNodeAll(value) {
      this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
    },
    // 所有菜單節點數據
    getMenuAllCheckedKeys() {
      // 目前被選中的菜單節點
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半選中的菜單節點
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    roleReset() {
      this.menuNodeAll = false;
      this.roleForm = {
        role_name: "",
        role_key: "",
        sort_num: "",
        status: 1,
        remark: "",
        menu_ids: "",
      };
    },

    //  添加
    addRole() {
      this.roleVisible = true;
      this.roleTitle = "添加";
      this.roleReset();
    },
    editRole(role_id) {
      this.roleVisible = true;
      this.roleTitle = "修改";
      this.roleReset();
      this.selectRoleId = role_id;
      this.selectMenu(this.selectRoleId);
    },

    selectMenu(role_id) {
      this.$api.roleInfo({ role_id: role_id }).then((res) => {
        this.roleForm = res.data;
        if (res.data.menu_ids != "") {
          this.checkedKeyList = res.data.menu_ids.split(",");
          // console.log(this.checkedKeyList);
          this.checkedKeyList.forEach((v) => {
            this.$nextTick(() => {
              this.$refs.menu.setChecked(v, true, false);
            });
          });
        }
      });
    },
    // 提交
    roleSubmit() {
      this.$refs["roleForm"].validate((valid) => {
        if (valid) {
          const roleLoading = this.$loading("正在提交...");
          if (
            this.roleForm.role_id != undefined &&
            this.roleForm.role_id != null
          ) {
            let params = {
              role_id: this.roleForm.role_id,
              role_name: this.roleForm.role_name,
              role_key: this.roleForm.role_key,
              sort_num: this.roleForm.sort_num,
              status: this.roleForm.status,
              remark: this.roleForm.remark,
              menu_ids: this.getMenuAllCheckedKeys().join(","),
            };
            this.$api.editRole(params).then((res) => {
              roleLoading.close();
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.roleVisible = false;
                this.roleReset();
                this.getRoleList();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.roleForm.menu_ids = this.getMenuAllCheckedKeys().join(",");
            this.$api.addRole(this.roleForm).then((res) => {
              roleLoading.close();
              if (res.code == 200) {
                this.$message.success("添加成功");
                this.roleVisible = false;
                this.getRoleList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    // 刪除
    delRole(id) {
      this.$confirm("此操作將永久刪除該角色, 是否繼續?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.delRole({ role_id: id }).then((res) => {
            if (res.code == 200) {
              this.getRoleList();
              this.$message.success("刪除成功");
            } else {
              this.$message.error("刪除失敗");
            }
          });
        })
        .catch((err) => err);
    },
    // 權限
    async jurisdiction(id) {
      this.role_id.id = id;
      this.Visible = true;
      const res = await this.$api.jurisdiction({ role_id: this.role_id.id });
      this.jurisdictionlist = res.data;
      console.log(this.jurisdictionlist);
      this.roleCheckedList = [];
      this.recursionList(this.jurisdictionlist);
      this.roleCheckedList.forEach((v) => {
        this.$nextTick(() => {
          this.$refs.tree.setChecked(v, true, false);
        });
      });
    },
    // 權限是否選中遞歸
    recursionList(obj) {
      obj.forEach((item) => {
        if (item.is_pitch == 1) {
          this.roleCheckedList.push(item.id);
        }
        if (item.children) {
          this.recursionList(item.children);
        } else {
          return false;
        }
      });
      console.log(this.roleCheckedList);
    },

    async save() {
      let arr = [];
      arr = this.getMenuAllCheckedKeys();
      const res = await this.$api.save({
        id: this.role_id.id,
        auth_ids: arr,
      });
      if (res.code == 200) {
        this.getlistrole();
        this.Visible = false;
        this.$message.success("設置權限成功");
      } else {
        this.$message.error("設置權限失敗");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/user/role.scss";
</style>
